
$.fn.fileCustom = function() {
	return this.each(function() {
		var container = $(this);
	  	var input 	= container.find('.filecustom__input');
	  	var output 	= container.find('.filecustom__output');

	  	$(this).addClass('filecustom__loaded');

		input.change(function() {
			var file = this.files[0];

			output.text(file.name);
			container.addClass('focus');
		});
	});
}

$('.filecustom').fileCustom();
